const PDFDownload = (() => {
  let $ = null
  let $navBarButton = null
  let $overlay = null
  const _init = function (_f, j) {
    $ = j
    $navBarButton = $(".util-nav .pdf-download")
    $overlay = $(".pdf-download-overlay")

    $navBarButton.removeClass("inactive")
    $navBarButton.addClass("highlight")

    $navBarButton.click(toggleDownloadBox.bind(this))
    $overlay.find(".close-overlay").click(toggleDownloadBox.bind(this))
    $overlay.find(".download-action").click(startDownload.bind(this))
  }
  const toggleDownloadBox = () => {
    $overlay.find("input[type=text]").removeClass("error").val("")
    $overlay.find("input[type=email]").removeClass("error").val("")
    $overlay.toggleClass("reveal")
  }
  const startDownload = (e) => {
    e.preventDefault()
    if (isValidForm()) {
      $overlay.find("form")[0].submit()
      toggleDownloadBox()
    }
  }
  const isValidForm = () => {
    let isValid = true
    //ad we use never lodash, do not start with it
    $overlay.find("input").each(function () {
      $(this).removeClass("error")
      if ($(this).attr("type") === "email") {
        //check email
        if (!isValidEmail($(this).val())) {
          isValid = false
          $(this).addClass("error")
        }
      } else if ($(this).attr("type") === "text") {
        //check empty
        if ($(this).val().trim() === "") {
          isValid = false
          $(this).addClass("error")
        }
      }
    })
    return isValid
  }
  const isValidEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(email)
  }

  //expose the render method
  return {
    init: _init,
  }
}).call()

module.exports = PDFDownload
