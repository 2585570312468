const Froogaloop = require("exports-loader?Froogaloop!vimeo-froogaloop")

const Television = (() => {
  //THIS HAS TO BE SYNCED TO THE STYLESHEET
  let skinImage = null
  let $injector = null
  let skinNaturalWidth
  let mask = null
  const maskConfig = {
    tv_skin_vintage: {
      x: 55,
      y: 60,
      width: 610,
    },
    tv_skin_modern: {
      x: 70,
      y: 90,
      width: 542,
    },
    tv_skin_canvas: {
      x: 48.6,
      y: 55,
      width: 929,
    },
    tv_skin_special_outdoor: {
      x: 454,
      y: 182,
      width: 322,
    },
    tv_skin_special_super8: {
      type: "super8",
      x: 1500,
      y: 630,
      width: 470,
    },
    tv_loewe: {
      x: 210,
      y: 90.5,
      width: 1741,
    },
    tv_skin_ipad_mini: {
      x: 90,
      y: 31,
      width: 720,
    },
    tv_skin_imac_2016: {
      x: 32,
      y: 30,
      width: 780,
    },
  }
  const _init = (_Foundation, $) => {
    //does the page have a tv?
    if ($(".content-box--tv-wrapper").length > 0) {
      skinImage = $(".tv-skin")[0]
      $injector = $(".video-injector")
      const skinFile = skinImage.src.substr(skinImage.src.lastIndexOf("/") + 1)
      for (const key in maskConfig) {
        if (skinFile.indexOf(key) === 0) {
          mask = maskConfig[key]
          buildTV()
          return
        }
      }
    }
  }
  const buildTV = () => {
    //normal
    //register resize function
    $(window).resize(() => {
      injectorResize(mask)
    })

    //register orientation change
    $(window).bind("orientationchange", () => {
      injectorResize(mask)
    })

    //then only go further when everything is loaded
    $(window).load(() => {
      //get the skins natural width
      //TODO in IE this is async
      skinNaturalWidth = getNaturalWidth(skinImage)

      //trigger the resize
      $(window).resize()
    })

    $(".site-content").css({
      overflow: "visible",
    })

    //is it a normal or a special tv
    if (mask.type !== undefined) {
      //prepare SUPER 8 SPECIAL
      $(".content-box").addClass("tv-special")

      //reposition close button
      $(".super8-btn-close").hide()
      $(".super8-btn-close").appendTo($(".header"))

      //registerSuper8OpenHandler();
    }

    const iframe = $injector.find("iframe")[0]
    const player = Froogaloop(iframe)
    player.addEvent("ready", () => {
      player.addEvent("pause", () => {
        $(".lightsoff-overlay").addClass("hidden")
        if (mask.type !== undefined) {
          closeSuper8()
        }
      })
      player.addEvent("finish", () => {
        $(".lightsoff-overlay").addClass("hidden")
        if (mask.type !== undefined) {
          closeSuper8()
        }
      })
      player.addEvent("play", () => {
        $(".lightsoff-overlay").removeClass("hidden")
        if (mask.type !== undefined) {
          openSuper8()
        }
      })
    })
  }
  /*
        registerSuper8OpenHandler = function() {
            $(skinImage).parent().bind('click', function() {
                openSuper8();
            }).bind(this);
        },

        unregisterSuper8OpenHandler = function() {
            $(skinImage).parent().unbind('click');
        },
        */
  const registerSuper8CloseHandler = function () {
    $(".super8-btn-close")
      .bind("click", () => {
        closeSuper8()
      })
      .bind(this)
  }
  const unregisterSuper8CloseHandler = () => {
    $(".super8-btn-close").unbind("click")
  }
  const injectorResize = () => {
    const scaleFactor = $(skinImage).width() / skinNaturalWidth
    $injector.css({
      width: mask.width * scaleFactor,
      left:
        $(skinImage).offset().left +
        mask.x * scaleFactor -
        $(".content-box--tv-wrapper").offset().left, //the last one is for the padding on the .content-box on small screens
      top: mask.y * scaleFactor,
    })

    $(document.body)
      .removeClass("portrait landscape")
      .addClass(
        window.innerWidth > window.innerHeight ? "landscape" : "portrait",
      )
  }
  const openSuper8 = () => {
    //unregisterSuper8OpenHandler();
    $(".content-box").addClass("zoomed")
    registerSuper8CloseHandler()
    $(".kn-icons").hide()
    setTimeout(() => {
      $(".super8-btn-close").show()
    }, 500)
  }
  const closeSuper8 = () => {
    unregisterSuper8CloseHandler()
    $(".content-box").removeClass("zoomed")
    //registerSuper8OpenHandler();
    setTimeout(() => {
      $(".super8-btn-close").hide()
      $(".kn-icons").show()
    }, 500)
  }
  const getNaturalWidth = (img) => {
    //mod from http://stackoverflow.com/questions/1832907/is-there-any-way-to-read-out-the-naturalwidth-of-an-image-with-jquery
    /*
     * NaturalWith calculation function. It has to be async, because sometimes(e.g. for IE) it needs to wait for already cached image to load.
     * @param onNaturalWidthDefined callback(img) to be notified when naturalWidth is determined.
     */
    const naturalWidth = img.naturalWidth
    if (naturalWidth) {
      return naturalWidth
    }
    //No naturalWidth attribute in IE<9 - calculate it manually.
    const newImg = new Image()
    newImg.src = img.src
    //Wait for image to load
    if (newImg.complete) {
      return newImg.width
    }
    $(newImg).load(() => {
      return newImg.width
    })
  }

  //expose the render method
  return {
    init: _init,
  }
}).call()

module.exports = Television
