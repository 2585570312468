const Soundplayer = (() => {
  //THIS HAS TO BE SYNCED TO THE STYLESHEET
  let $navBarButton = null
  let $audioEl = null
  //is the audio playing?
  let isPlaying = false
  //is the audio enabled? //this stays over a page lifetime
  let isEnabled = false
  const _init = (Foundation, $) => {
    //assign the button of the nav bar
    $navBarButton = $(".util-nav .soundplayer")

    //register the audio element and its behavours
    $audioEl = $("audio")
    $audioEl.bind("ended", onEnded)
    $navBarButton.off("click").on("click", toggleButton)

    //enable on desktops
    isEnabled = window.device.desktop()

    //try to load a soundfile if available
    if (!$.isEmptyObject($audioEl.data("audio-data"))) {
      //console.log($audioEl.data('audio-data'));

      const soundfiles = $audioEl.data("audio-data").soundfiles
      //console.log(soundfiles);

      //are we in mobile or desktop mode?
      let soundfile
      if (Foundation.MediaQuery.current === "small") {
        soundfile = soundfiles.mobile
      } else {
        soundfile = soundfiles.default
      }

      if (soundfile !== undefined) {
        _load(soundfile, $audioEl.data("audio-data").autostart)
      }
    } else {
      updateButtonState()
    }
  }
  const _load = (soundfile, autostart = false) => {
    if (soundfile.length > 0) {
      $audioEl[0].src = soundfile
      $audioEl[0].pause()
      $navBarButton.removeClass("inactive")
      $navBarButton.addClass("highlight")
    }

    if (autostart && isEnabled) {
      startAudioTrack()
    }
    updateButtonState()
  }
  const startAudioTrack = () => {
    isPlaying = true
    $audioEl[0].play()
  }
  const pauseAudioTrack = () => {
    $audioEl[0].pause()
    isPlaying = false
  }
  /* does a stop and UNLOAD */
  const _stop = () => {
    $audioEl[0].src = ""
    $navBarButton.addClass("inactive")

    isPlaying = false
    updateButtonState()
  }
  const toggleButton = () => {
    isEnabled = !isEnabled
    //now check if we have an audio to start
    if (isEnabled) {
      if ($audioEl[0].src.length > 0) {
        startAudioTrack()
      }
    } else {
      pauseAudioTrack()
    }
    updateButtonState()
  }
  const updateButtonState = () => {
    if (isEnabled) {
      if (isPlaying) {
        $navBarButton.removeClass("is-playing").addClass("is-playing")
      } else {
        $navBarButton.removeClass("is-playing")
      }
    } else {
      $navBarButton.removeClass("is-playing")
    }
  }
  const onEnded = () => {
    _stop()
  }

  //expose the render method
  return {
    init: _init,
    stop: _stop,
    load: _load,
  }
}).call()

module.exports = Soundplayer
