const Tearsheets = (() => {
  let $ = null
  let currentElement
  const _init = (j, flkty) => {
    $ = j
    currentElement = $("")

    flkty.on("settle", onCellChange.bind(flkty))

    $(".has-tearsheet .tearsheet-toggle").click((e) => {
      toggle(e)
    })
  }
  const toggle = (e) => {
    const toggle = $(e.currentTarget)

    //tearsheets have been changed. now its at
    let tearsheet = toggle.parent().nextAll(".flex-tearsheet-wrapper")

    //but if any old structure is still rendered, we just check for it
    if (tearsheet.length === 0) {
      //should never be true
      tearsheet = toggle.parent().next().nextAll(".flex-tearsheet-wrapper")
    }

    if (tearsheet.attr("aria-hidden") === "true") {
      tearsheet.hide().fadeIn().attr("aria-hidden", "false")
      toggle.addClass("is-open")

      toggle.closest(".gallery-cell").find(".caption-image").hide()
      toggle.closest(".gallery-cell").find(".caption-tearsheet").show()
    } else {
      tearsheet.attr("aria-hidden", "true").fadeOut()
      toggle.removeClass("is-open")

      toggle.closest(".gallery-cell").find(".caption-image").show()
      toggle.closest(".gallery-cell").find(".caption-tearsheet").hide()
    }
  }
  const onCellChange = function () {
    //hide any visible sheet on the old current elment
    currentElement
      .find(".flex-tearsheet-wrapper[aria-hidden=false]")
      .attr("aria-hidden", "true")
      .hide()
    currentElement.find(".tearsheet-toggle").removeClass("is-open")
    currentElement
      .find(".tearsheet-toggle")
      .closest(".gallery-cell")
      .find(".caption-image")
      .show()
    currentElement
      .find(".tearsheet-toggle")
      .closest(".gallery-cell")
      .find(".caption-tearsheet")
      .hide()
    //update current elment
    currentElement = $(this.selectedElement)
  }

  //expose the render method
  return {
    init: _init,
  }
}).call()

module.exports = Tearsheets
