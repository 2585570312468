//init the udraggable
//http://grantm.github.io/jquery-udraggable/#options
require("imports-loader?jQuery=jquery!./vendor/jquery.event.ue")
require("imports-loader?jQuery=jquery!./vendor/jquery.udraggable")
require("imports-loader?jQuery=jquery!./vendor/jquery.doubletap")

const SoundPlayer = require("./soundplayer.js")

const Carpet = (() => {
  let defaultThumbHeight = 400 //default thumb height in px,
  let scaleFactor = 0.4 //will be calculated to real factor below
  let canvasScaleFactor = 1.0 //only neeeded for to small canvas-ext screens
  const defaultPadding = {
    top: 50,
    left: 50,
    bottom: 50,
    right: 50,
  }
  const maxSpawnAreaDimensions = {
    width: 800,
    height: 600,
  }
  const extendedOffset = { x: 130, y: 50 }
  let zIndexPointer = 0
  let canvas = null
  let isCarpetExt = false
  let currentItem = null
  //prevent doubleclick/doubletap twice execution
  let currentDoubleClick = null
  const _init = (Foundation, $) => {
    //does the page have a carpet?
    if ($(".content-box--carpet .carpet").length > 0) {
      //carousel only for medium up
      if (Foundation.MediaQuery.current !== "small") {
        $(window).load(() => {
          buildCarpet()
        })
      }
    }
  }
  const buildCarpet = () => {
    canvas = $(".carpet")
    isCarpetExt = $("body").hasClass("visu-carpet-ext")

    //collect all carpet items:
    let maxItemWidth = 0
    let maxItemHeight = 0
    const items = $(".carpet-item").each(function () {
      maxItemWidth = Math.max(maxItemWidth, $($(this).find("img")[0]).width())
      maxItemHeight = Math.max(
        maxItemHeight,
        $($(this).find("img")[0]).height(),
      )
      return this
    })

    //determine spawn areas dimensions and limit it to the max area
    let spawnArea = {}
    if (!isCarpetExt) {
      spawnArea = {
        width: Math.min(
          maxSpawnAreaDimensions.width,
          canvas.width() - defaultPadding.left - defaultPadding.right,
        ),
        height: Math.min(
          maxSpawnAreaDimensions.height,
          canvas.height() - defaultPadding.top - defaultPadding.bottom,
        ),
      }
    } else {
      //full spawn area minus paddings for carpet ext
      spawnArea = {
        width: canvas.width() - defaultPadding.left - defaultPadding.right,
        height: canvas.height() - defaultPadding.top - defaultPadding.bottom,
      }
    }

    //and now set the position of the spawn areas
    spawnArea.x = (canvas.width() - spawnArea.width) / 2
    spawnArea.y = (canvas.height() - spawnArea.height) / 2

    //HACK for reto: for smaller than 1024, thats mainly ipad, we will minish the default thumb height
    if (canvas.width() < 1025 && !isCarpetExt) {
      defaultThumbHeight = 300
    }
    //calculate a standard scale factor (all uploaded images have the same height)
    scaleFactor = defaultThumbHeight / maxItemHeight

    if (!isCarpetExt) {
      //limit too big items
      if (maxItemWidth * scaleFactor > spawnArea.width) {
        scaleFactor = Math.min(
          scaleFactor,
          spawnArea.width / (maxItemWidth * scaleFactor),
        )
      }
      if (maxItemHeight * scaleFactor > spawnArea.height) {
        scaleFactor = Math.min(
          scaleFactor,
          spawnArea.height / (maxItemHeight * scaleFactor),
        )
      }

      //round the scale factor down with 2 digits
      scaleFactor = Math.floor(scaleFactor * 100) / 100
      //console.log("final scale factor: " + scaleFactor);
      spawnCarpetStandard(items, spawnArea)
    } else {
      //check if scale factor has to be limited for the spawn area.
      //this is slightly more complicated than with the normal carpet
      //as we have to deal with all offsets.

      //calculate the needed width and height.
      const neededArea = { width: 0, height: 0 }
      neededArea.width =
        $(items[0]).width() * scaleFactor +
        (items.length - 1) * extendedOffset.x
      neededArea.height =
        $(items[0]).height() * scaleFactor +
        (items.length - 1) * extendedOffset.y

      //console.log(spawnArea);
      //console.log(neededArea);

      //get the minimum delta scale factor
      canvasScaleFactor = Math.min(
        spawnArea.width / neededArea.width,
        spawnArea.height / neededArea.height,
      )
      //but limit it to 1
      canvasScaleFactor = Math.min(canvasScaleFactor, 1.0)
      //update neededArea
      neededArea.width *= canvasScaleFactor
      neededArea.height *= canvasScaleFactor

      //round the canvasScaleFactor down with 2 digits
      canvasScaleFactor = Math.floor(canvasScaleFactor * 100) / 100

      //console.log("final scale factor: " + scaleFactor);
      spawnCarpetExtended(items, spawnArea, neededArea)
    }
  }
  const spawnCarpetStandard = (items, spawnArea) => {
    //now set all items
    let item = null
    //calculate a random spawn point inside the spawn area
    const spawnPoint = { x: 0, y: 0 }
    let iWidth = 0
    let iHeight = 0
    for (let i = 0; i < items.length; i++) {
      item = items[i]
      iWidth = $(item).width()
      iHeight = $(item).height()

      //calculate a spawn point inside the spawn area
      spawnPoint.x = Math.random() * (spawnArea.width - iWidth * scaleFactor)
      spawnPoint.y = Math.random() * (spawnArea.height - iHeight * scaleFactor)

      //do this first, as it resets the left and top positions
      $(item).udraggable({
        containment: [
          0,
          0,
          canvas.width() - iWidth * scaleFactor,
          canvas.height() - iHeight * scaleFactor,
        ],
        start() {
          updateZIndex(this)
        },
      })

      //randomly position INSIDE spawn box
      $(item).css("left", spawnArea.x + spawnPoint.x)
      $(item).css("top", spawnArea.y + spawnPoint.y)
      //z stacking - inital its based on render ordering - first element on top.
      $(item).css("z-index", items.length - zIndexPointer++)

      //make sure thy are thumbs
      $(item).removeClass("thumb").addClass("thumb")
      $(item).css({ transform: `scale(${scaleFactor}) translateZ(0)` })

      //fade in with randomly chosen delay for nicer animation
      $(item)
        .delay(400 + Math.random() * 400)
        .animate({ opacity: 1 }, 500)

      //register close button
      registerCloseButton(item)

      //register doubleclick
      bindDoubleClick(item)
    }
    //increase by one to start at the top
    zIndexPointer++
  }
  const spawnCarpetExtended = (items, spawnArea, neededArea) => {
    //now set all items
    let item = null
    const spawnPoint = { x: 0, y: 0 }
    const spawnPointOrigin = {
      x:
        spawnArea.x * canvasScaleFactor +
        spawnArea.width -
        ((spawnArea.width - neededArea.width) / 2) * canvasScaleFactor,
      y:
        spawnArea.y * canvasScaleFactor +
        ((spawnArea.height - neededArea.height) / 2) * canvasScaleFactor,
    }
    let iWidth = 0
    let iHeight = 0

    for (let i = 0; i < items.length; i++) {
      item = items[i]
      iWidth = $(item).width() * scaleFactor * canvasScaleFactor
      iHeight = $(item).height() * scaleFactor * canvasScaleFactor

      //calculate a spawn point inside the spawn area
      spawnPoint.x =
        spawnPointOrigin.x -
        iWidth -
        extendedOffset.x * (items.length - i - 1) * canvasScaleFactor
      spawnPoint.y =
        spawnPointOrigin.y +
        (items.length - i - 1) * (extendedOffset.y * canvasScaleFactor)

      //do this first, as it resets the left and top positions
      $(item).udraggable({
        containment: [0, 0, canvas.width() - iWidth, canvas.height() - iHeight],
        start() {
          updateZIndex(this)
        },
      })

      //randomly position INSIDE spawn box
      $(item).css("left", spawnPoint.x)
      $(item).css("top", spawnPoint.y)
      //z stacking - inital its based on render ordering - first element on top.
      $(item).css("z-index", items.length - zIndexPointer++)

      //make sure thy are thumbs
      $(item).removeClass("thumb").addClass("thumb")
      $(item).css({
        transform: `scale(${scaleFactor * canvasScaleFactor}) translateZ(0)`,
      })

      //fade in with randomly chosen delay for nicer animation
      $(item)
        .delay(400 + Math.random() * 400)
        .animate({ opacity: 1 }, 500)

      //register close button
      registerCloseButton(item)

      //register doubleclick
      bindDoubleClick(item)
    }
    //increase by one to start at the top
    zIndexPointer++
  }
  const onItemDoubleClick = function () {
    //prevent double execution of doubleckick+tab
    if (currentDoubleClick !== this) {
      currentDoubleClick = this

      //trigger a close on any open item
      if (currentItem !== null) {
        $(currentItem).find(".btn-close").click()
        currentItem = null
      }

      //unBindDoubleClick(this);
      updateZIndex(this)

      //store the current values
      $(this).attr("data-thumb-x", $(this).css("left"))
      $(this).attr("data-thumb-y", $(this).css("top"))
      $(this).attr(
        "data-drag-containment",
        $(this).udraggable("option").containment,
      )

      //center it
      $(this).css(
        "left",
        `${Number.parseInt(
          ((canvas.width() - $(this).width()) / 2 / canvas.width()) * 100,
        )}%`,
      )
      $(this).css(
        "top",
        `${Number.parseInt(
          ((canvas.height() - $(this).height()) / 2 / canvas.height()) * 100,
        )}%`,
      )

      //this triggers growth
      $(this).removeClass("thumb")
      $(this).css({ transform: "scale(1) translateZ(0)" })

      //expand containment
      //console.log($(this).udraggable('option', 'containment'));
      $(this).udraggable("option", "containment", [
        -($(this).width() - 100),
        -($(this).height() - 100),
        canvas.width() - 100,
        canvas.height() - 100,
      ])

      currentItem = this

      sendSoundFileToPlayer(this)
    } else {
      //check if the click was on a fully expanded element, otherwise it would
      //be the feared double execution of the tab above.
      //well kinda hacky
      if (this.getBoundingClientRect().width === $(this).find("img").width()) {
        $(this).find(".btn-close").click()
      }
    }
  }
  const onItemRestore = function () {
    currentDoubleClick = null

    //only stop the audio player if closing element had audio data
    stopCurrentSoundPlayer()
    currentItem = null

    //stopPlayer();

    //shrink
    $(this).addClass("thumb")
    $(this).css({ transform: `scale(${scaleFactor}) translateZ(0)` })

    $(this).udraggable(
      "option",
      "containment",
      $(this).attr("data-drag-containment").split(","),
    )
    //console.log($(this).udraggable('option', 'containment'));

    //restore to position
    $(this).css("left", $(this).attr("data-thumb-x")).removeAttr("data-thumb-x")
    $(this).css("top", $(this).attr("data-thumb-y")).removeAttr("data-thumb-y")
    $(this).removeAttr("data-drag-containment")

    //finally, reactivate the doubleclick
    //bindDoubleClick(this);
  }
  const registerCloseButton = (target) => {
    $(target)
      .find(".btn-close")
      .unbind("click touchstart")
      .bind("click touchstart", onItemRestore.bind(target))
  }
  const bindDoubleClick = (target) => {
    $(target)
      .unbind("dblclick doubletap", onItemDoubleClick)
      .bind("dblclick doubletap", onItemDoubleClick.bind(target))
  }
  /*
        unBindDoubleClick = function(target) {
            $(target).unbind('dblclick doubletap', onItemDoubleClick);
        },
        */
  const updateZIndex = (target) => {
    $(target).css("z-index", zIndexPointer++)
  }
  const sendSoundFileToPlayer = (target) => {
    //try to load a soundfile if available
    if (!$.isEmptyObject($(target).data("audio-data"))) {
      SoundPlayer.load(
        $(target).data("audio-data").soundfile,
        $(target).data("audio-data").autostart,
      )
    }
  }
  const stopCurrentSoundPlayer = () => {
    if (
      currentItem != null &&
      !$.isEmptyObject($(currentItem).data("audio-data"))
    ) {
      SoundPlayer.stop()
    }
  }

  //expose the render method
  return {
    init: _init,
  }
}).call()

module.exports = Carpet
